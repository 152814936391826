.catalog__container {
    margin-bottom: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.catalog_cards__container {
    margin: 0 auto;
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: column; */
    gap: 5rem;
}
.catalog__card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    width: 25vw;
    height: 50vh;
    background-color: lightgray;
    border-radius: 5%;
}
.catalog__card:hover {
    cursor: pointer;
    scale: 1.05;
}
.catalog__card_title {
    font-size: 1.5rem;
    text-align: center;
    margin: 0 auto;
}
.catalog__container_title {
    font-size: 1.5rem;
    text-align: center;
}
.catalog__card_img {
    margin: 0 auto;
    width: 90%;
}

@media(max-width: 1400px) {
    .catalog__card_img {
        width: 95%;
    }
    .catalog__card {
        gap: 0.5rem;
        width: 25vw;
        height: 25rem;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
    .catalog_cards__container {
        gap: 4rem;
    }
}

@media(max-width: 1150px) {
    .catalog__card {
        gap: 0.5rem;
        width: 25vw;
        height: 20rem;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
    .catalog__card_title {
        font-size: 1rem;
    }
}

@media(max-width: 850px) {
    .catalog__card_img {
        width: 99%;
    }
    .catalog__card {
        gap: 0.5rem;
        width: 30%;
        height: 17rem;
    }
}

@media(max-width: 650px) {
    .catalog_cards__container {
        gap: 1rem;
        width: 80%;
    }
    .catalog__card_img {
        width: 99%;
    }
    .catalog__card {
        gap: 0.5rem;
        width: 10rem;
        height: 15rem;
    }
    .catalog__card_title {
        font-size: 0.7rem;
    }
}