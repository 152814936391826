.info_cards__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
}

@media(max-width: 768px) {
  .info_cards__wrapper {
    width: 90%;
  }
}