.button {
  width: 150px;
  height: 55%;
  font-size: 1em;
  /* min-height: 100%; */
  text-align: center;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  border: 1.5px solid #2c5200;
  border-radius: 0.5em;
  background-color: inherit;
  font-family: Stolzl;
  font-weight: 100;
  font-size: 1rem;
}
.button:hover {
  cursor: pointer;
  background-color: #2e2b28;
  color: #ffffff;
  transform: skew(-5deg);
  -webkit-transform: skew(-5deg);
  -moz-transform: skew(-5deg);
  -ms-transform: skew(-5deg);
  -o-transform: skew(-5deg);
}
.cl_btn_2 {
  display: flex;
  justify-content: center;
  background-color: inherit;
  border: none;
}
.cl_btn_2 div {
  cursor: pointer;
  position: relative;
  height: 68px;
  width: 50px;
}
.cl_btn_2 .leftright {
  height: 4px;
  width: 40px;
  position: absolute;
  margin-top: 24px;
  background-color: #000000;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.cl_btn_2 .rightleft {
  height: 4px;
  width: 40px;
  position: absolute;
  margin-top: 24px;
  background-color: #000000;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}
.cl_btn_2 .close_btn {
  margin: 54px 0 0 -5px;
  position: absolute;
  color: #337ab7;
  font-family: Tahoma, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.cl_btn_2 div:hover .leftright {
  transform: rotate(-45deg);
  background-color: #2c5200;
}
.cl_btn_2 div:hover .rightleft {
  transform: rotate(45deg);
  background-color: #2c5200;
}
.cl_btn_2 div:hover .close-btn {
  opacity: 1;
}

@media (max-width: 768px) {
  .btn {
    margin-right: 0;
    padding: 3%;
  }
}
@media (max-width: 390px) {
  .btn {
    margin-right: 0;
    padding: 3%;
    font-size: 12px;
  }
}

@media(max-width: 1250px) {
  .button {
      font-size: 0.9rem;
  }
}

@media(max-width: 1050px) {
  .button {
      font-size: 0.8rem;
      width: 120px;
      height: 45%;
  }
}

@media(max-width: 860px) {
  .button {
    font-size: 0.8rem;
    width: 100px;
    height: 45%;
  }
}

@media(max-width: 450px) {
  .button {
    width: 80px;
    height: 40%;
  }
}