.information__contact_container {
  display: flex;
  gap: 7px;
}
.information__contact {
  display: flex; 
  flex-direction: column;
}
.information__title {
  color: #2E2B28;
  font-weight: 700;
  font-size: 22px;
}