.header__navbar {
    border-top: 1.5px solid #2e2b28;
    border-bottom: 1.5px solid #2e2b28;
}

.header__navbar_links {
    display: flex;
    width: 100%;
    padding: 10px 0;
    justify-content: space-evenly;
    align-items: center;
    gap: 5%;
}

.header__navbar_link {
    text-decoration: none;
}

.header__navbar_link:hover {
    cursor: pointer;
    color: #0b8500;
}

@media (max-width: 768px) {
    .header__navbar {
        display: none;
        z-index: 100;
        position: absolute;
        background: #2e2b28;
        right: -100%;
        width: 50%;
    }
    .header__navbar.active {
        height: 100vh;
        display: block;
        animation-name: show;
        animation-duration: 0.5s;
        top: 0%;
        right: 0%;
    }
    .header__navbar.active > .header__navbar_links {
        margin: 0 auto;
        margin-top: 7em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
    .header__navbar_link {
        color: #cee5e3;
    }
}

@keyframes show {
    from {right: -100%;}
    to {right: 0%;}
  }

  @media (max-width: 480px) {
    .header__navbar {
        width: 70%;
    }
}