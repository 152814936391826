.catalog_product__container {
  margin-bottom: 3rem;
}

.catalog_product_wrapper {
  max-width: 95vw;
  padding: 1rem 0;
  /* height: 65vh; */
  margin: 1rem auto 3rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid #2E2B28;
  border-radius: 1em;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  -ms-border-radius: 1em;
  -o-border-radius: 1em;
  background-color: azure;
}

.catalog__description_lonking__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.catalog_product__description_wrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.catalog_product__description {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.catalog_product__description_title_text {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

.catalog_product_img {
  margin: 0 auto;
  scale: 1.1;
  width: 25vw;
}

.catalog_product_title {
  text-align: center;
}

@media(max-width: 1400px) {
  .catalog_product__description_wrapper {
    gap: 0.5rem;
  }
  .catalog_product__description_text {
    font-size: 0.9rem;
  }
  .catalog_product_img {
    scale: 1;
    width: 25vw;
  }
  .catalog_product__description {
    gap: 0.3rem;
  }
  .catalog_product_wrapper {  
    gap: 0.5rem;
  }
}

@media(max-width: 1150px) {
  .catalog_product__description_wrapper {
    gap: 0.5rem;
  }
  .catalog_product__description_text {
    font-size: 0.8rem;
  }
  .catalog_product_img {
    width: 23vw;
  }
  .catalog_product__description {
    gap: 0.3rem;
  }
  .catalog_product_wrapper {  
    gap: 0.5rem;
  }
  .catalog_product__description_title_text {
    font-size: 1rem;
  }
}
@media(max-width: 850px) {
  .catalog_product__description_wrapper {
    gap: 0rem;
  }
  .catalog_product__description_text {
    font-size: 0.7rem;
  }
  .catalog_product_img {
    width: 30%;
    margin: 0;
  }
  .catalog_product__description {
    gap: 0.15rem;
  }
  .catalog_product_wrapper {  
    gap: 0rem;
  }
  .catalog_product__description_title_text {
    font-size: 0.9rem;
    width: 100%;
  }
  .catalog_product_title {
    font-size: 1.1rem;
  }
  .catalog__description_lonking {
    font-size: 0.85rem;
  }
}
@media(max-width: 550px) {
  .catalog_product__description_wrapper {
    width: 70vw;
    gap: 0rem;
  }
  .catalog_product__description_text {
    font-size: 0.7rem;
  }
  .catalog_product_img {
    width: 50vw;
    margin: 0;
  }
  .catalog_product__description {
    gap: 0.15rem;
    padding-left: 0;
    margin: 1rem auto;
  }
  .catalog_product_wrapper { 
    gap: 1rem;
  }
  .catalog_product__description_title_text {
    font-size: 0.9rem;
    width: 100%;
  }
  .catalog_product_title {
    font-size: 1.1rem;
  }
}