.header__numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
}

.header__numbers__textlink:hover {
    cursor: pointer;
    color: #0b8500;
}

@media(max-width: 768px) {
    .header__numbers {
        width: 35%;
    }
}

@media(max-width: 400px) {
    .header__numbers {
        font-size: 0.6rem;
    }
}