.main_photo__wrapper {
  width: 100%;
  height: 37vw;
  background-image: url('../../../assets/main-photo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

.main_photo__title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 3rem;
}

.main_title__wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(85%, 3rem);
  -webkit-transform: translate(85%, 3rem);
  -moz-transform: translate(85%, 3rem);
  -ms-transform: translate(85%, 3rem);
  -o-transform: translate(85%, 3rem);
}

.main_photo__text {
  text-align: center;
  width: 65%;
  font-size: 1.3rem;
}

/* Animation */
.main_photo__title {
  animation: typewriter 5s steps(44) 1s 1 normal both,   blinkTextCursor 500ms steps(44) infinite normal;
  -webkit-animation: typewriter 5s steps(44) 1s 1 normal both,   blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {width: 0;}
  to {width: 100%;}
}
@keyframes blinkTextCursor {
  from {border-right-color: #2e2b28;}
  to {border-right-color: transparent;}
}

@media(max-width: 1180px) {
  .main_photo__title {
    font-size: 3rem;
  }
  .main_photo__text {
    width: 75%;
    font-size: 1.3rem;
  }
}

@media(max-width: 1035px) {
  .main_photo__title {
    font-size: 2.5rem;
  }
  .main_photo__text {
    width: 85%;
    font-size: 1.1rem;
  }
}

@media(max-width: 860px) {
  .main_photo__title {
    font-size: 2rem;
  }
  .main_photo__text {
    width: 85%;
    font-size: 0.9rem;
  }
}

@media(max-width: 760px) {
  .main_photo__title {
    margin-top: -10%;
    font-size: 2rem;
  }
  .main_photo__text {
    width: 85%;
    font-size: 0.9rem;
  }
}

@media(max-width: 640px) {
  .main_photo__title {
    margin-top: -15%;
    font-size: 1.7rem;
  }
  .main_photo__text {
    width: 95%;
    font-size: 0.75rem;
  }
}

@media(max-width: 485px) {
  .main_photo__title {
    margin-top: -20%;
    font-size: 1.5rem;
  }
  .main_photo__text {
    width: 95%;
    font-size: 0.6rem;
  }
}

@media(max-width: 400px) {
  .main_photo__title {
    margin-top: -30%;
    font-size: 1.4rem;
  }
  .main_photo__text {
    width: 95%;
    font-size: 0.55rem;
  }
}

@media(max-width: 400px) {
  .main_photo__text {
    width: 100%;
    font-size: 0.50rem;
  }
}