.our_advantages__wrapper {
  /* background-size: 35%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 3rem;
}

.our_advantages__text_wrapper {
  width: 80%; 
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
}

.one_advantages__wrapper {
  margin-right: 2rem;
  width: 45%;
  display: flex;
  align-items: center;
  gap: 2rem;
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
}

.our_advantages__title {
  text-align: center;
  font-size: 2rem;
}

.our_advantages__text {
  list-style: none;
  /* width: 50%; */
}

@media(max-width: 815px) {
  .our_advantages__text_wrapper {
    width: 90%; 
  }
  .one_advantages__wrapper {
    width: 45%;
  }
  .our_advantages__text {
    font-size: 0.8rem;
  }
  .advantages__logo {
    width: 40px;
  }
}

@media(max-width: 726px) {
  .our_advantages__title {
    font-size: 1.5rem;
  }  
  .our_advantages__text_wrapper {
    width: 90%; 
  }
  .one_advantages__wrapper {
    margin-right: 0;
    width: 75%;
  }
  .our_advantages__text {
    font-size: 0.85rem;
  }
  .advantages__logo {
    width: 45px;
  }
}